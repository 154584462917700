import { createContext, useState } from 'react';
import { DefaultTextConfig } from '../components/ModelConfig/DefaultTextConfig';
const TextConfigContext = createContext({});


/**
 * @param {Object} props - The properties passed to the component.
 * @returns {JSX.Element} A ChatContext.Provider element.
 */
const TextConfigContextProvider = (props) => {

  const [temperature, setTemperature] = useState(DefaultTextConfig.temperature);
  const [topP, setTopP] = useState(DefaultTextConfig.top_p);
  const [topK, setTopK] = useState(DefaultTextConfig.top_k);
  const [maxTokens, setMaxtokens] = useState(DefaultTextConfig.max_tokens_to_sample);
  const [stopSequences, setStopSequences] = useState(DefaultTextConfig.stop_sequences);
  const [template, setTemplate] = useState(DefaultTextConfig.template);
  const [ragInfo, setRagInfo] = useState(null);
  const [queryInfo, setQueryInfo] = useState(null);
  const [isBingSearchEnabled, setIsBingSearchEnabled] = useState(DefaultTextConfig.isBingSearchEnabled);

  return (
    <TextConfigContext.Provider value={[
      temperature, setTemperature,
      topP, setTopP,
      topK, setTopK,
      maxTokens, setMaxtokens,
      stopSequences, setStopSequences,
      template, setTemplate,
      ragInfo, setRagInfo,
      queryInfo, setQueryInfo,
      isBingSearchEnabled, setIsBingSearchEnabled
    ]}>
      {props.children}
    </TextConfigContext.Provider>
  );
};

export { TextConfigContext, TextConfigContextProvider };

