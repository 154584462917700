import use_case_1 from '../../assets/use_case_1.png';
import use_case_2 from '../../assets/use_case_2.png';
import use_case_3 from '../../assets/use_case_3.png';
import use_case_4 from '../../assets/use_case_4.png';

import RootCauseDiagnostics, { reportTypes as RootCauseDiagnosticsReportTypes } from './RootCauseDiagnostics';
import RootCauseReportSelection, { reportTypes as RootCauseReportTypes } from './RootCauseReportSelection';

const UseCases = [
  {
    modelType: 'text',
    title: 'Contract Summary',
    key: 'contract_summary_menu',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to instantly summarize the following contract types:
    <ul class="list-disc mx-6">
    <li>General Contract</li>
    <li>Endorsement Contract</li>
    <li>Licensing Agreement</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ol class="list-decimal mx-6">
    <li>Upload your contract using the paperclip icon.</li>
    <li>Follow the instructions on the prompt that appears once the document has uploaded.</li>
    <li>Once SkechAI provides you with an initial analysis, you can ask follow-up questions about the contract or the analysis provided by SkechAI.</li>
    </ol>
    <br/>
    <br/>
    We've set configuration parameters on the right-hand side for low creativity and high accuracy; however, you can adjust these parameters to get different results.
    `,
    imageName: use_case_1,
    template: `
You are to act as a legal analyst specifically for Skechers. Your task involves summarizing a contract provided to you. 

In order to do so you will need to ask me what type of document analysis to prepare. The available options are as follows. Please Show these options back to me when asking which one I'd like to use:

Analysis Types:
- General Contract
- Endorsement Contract
- Licensing Agreement

Upon receiving the specific type of contract, refer to the detailed instructions provided for that contract type to complete both a comprehensive summary and a narrative-style summary tailored for Skechers leadership.
Follow these steps for EACH document sent:
1. Confirm the analysis type to be performed by showing the user the list and asking them to choose.
2. Do NOT generate the analsysis until the user has explicitly confirmed the analysis type by choosing from the list provided.
3. Once the contract type is identified, proceed with the summary as per the instructions related to the chosen type.

Here are instructions for you to follow to prepare your summary for each report type:

### General Contract:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties Involved
- Contract purpose (2-3 sentences)
- Payment Terms (including total fees)
- Skechers' obligations (if any present, otherwise omit) 
- Termination fees (if any present, otherwise omit) 
- Benefit to Skechers

3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### Endorsement Contract:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties involved
- Product endorsed
- Length of the endorsement deal
- Overview of the compensation Skechers will provide, including the following if applicable: fees, stock grants, royalty payments, base royalty, product offered to endorsee
- Endorser obligations for the following is applicable: promotions, appearances, social media, production days
- Additional commitments from Skechers including the following in applicable: charitable contributions.
- If the contract contains a bonus
3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### Licensing Agreement:

Follow these steps:

1. Read the contract below

2. Write a COMPLETE SUMMARY including the following points:
- Parties involved
- Type of product
- Skechers trademark
- Territory
- Contract Terms
- Payment terms including the following if applicable: royalties, advance, minimums. Do not include the following: projected sales, selloff, dispute resolution.  3. Remember the summary. We'll refer to it as #SUMMARY

4. Use the information from #SUMMARY and format it into a narrative style paragraph form summary. Remember this narrative style paragraph form summary. We'll refer to it as #NARRATIVE.

5. Print out ONLY the #NARRATIVE and #SUMMARY for the user to review. Do not provide any additional lead-in or follow-up text in addition to the format requested. Do not explain the steps to take or instructions you will follow to generate the analysis.

### End of list

Here is the contract:
<text>
{{PROMPT}}
</text>
`,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 4096,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'root_cause_analysis',
    title: 'Root Cause Analysis',
    environments: ['*'],
    roles: ['root_cause_analysis'],
    reportParamsModal: RootCauseReportSelection,
    reportTypes: RootCauseReportTypes,
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use Skechers to analyze customer support call transcripts for root causes and preventative actions.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    Initiate your root cause analysis by selecting your desired time period along with the specific type of analysis you want to undertake. Click "Go" to begin.
`,
    imageName: use_case_1,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'root_cause_diagnostics',
    title: 'Root Cause Diagnostics',
    environments: ['*'],
    roles: ['KPMG Consultants'],
    reportParamsModal: RootCauseDiagnostics,
    reportTypes: RootCauseDiagnosticsReportTypes,
    description: `
    <h2 class="font-bold">Purpose</h2>
    Get detailed listing of call logs for the selected scope, including date/time, duration, sentiment, root causes and reasons, and ID.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    Select the date range for the call logs you want to analyze, then click "Go" to begin.
`,
    imageName: use_case_1,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'product_description_generation',
    title: 'Product Description Generation',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to generate product descriptions for Skechers’ products. SkechAI will leverage the style and feature information provided and generate potential product descriptions that captivate your audience.
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload your product spreadsheet (.csv) using the paperclip icon or copy/paste the text into the chat and hit send. </li>
    <li>When prompted, tell SkechAI which product or products you want descriptions for, by style name. You can also just say "the first 10", for example, or "all of them".</li>
    <li>Once SkechAI provides you with an initial analysis, you can request edits to the product descriptions provided.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you to encourage a little creativity, but you can adjust these parameters to get different results.
    `,
    imageName: use_case_2,
    template: `

Save this information for our conversation: {{PROMPT}}
  
After reading and saving the above, prompt me for which products I want by saying something like “Got it. Just tell me which products you’re interested in and what product line they belong to and I”ll generate descriptions for them”. Then use the following instruction to generate descriptions as requested:
  
You are a marketing copywriter. Using the format below, create a description appealing to a broad set of buyers the requested product(s).
  
The general format is 2 sentences, totaling 55 words or less. Contents of each sentence are:
First sentence: a strong romance statement describing overall appeal of the shoe, followed by product line and full product name. Do not include features of the shoe in the first sentence. 
Seconds sentence: begin with a transition and then list out the features of the shoe in plain text with no added creativity. Do not add anything after listing the features. Only use features listed for this shoe.
  
It is very important to EXCLUDE these items from the feature list: heel height, Skechers® logo detail, information about electrical hazard safety, or the word “shoe”.
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'employee_survey_summarization',
    title: 'Survey Summarization',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to summarize employee surveys from retail stores. SkechAI will carefully analyze your survey and provide actionable insights on the following, if applicable:
    <ul class="list-disc mx-6">
    <li>Employee satisfaction insights</li>
    <li>Product insights</li>
    <li>Actions to be taken to improve employee satisfaction</li>
    <li>Actions to be taken to improve the product</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload your survey results (PDF, Word, or CSV) using the paperclip icon or copy/paste the text into the chat and hit send.</li>
    <li>Once SkechAI provides you with an initial analysis, you can ask follow-up questions about the survey results or the analysis provided by SkechAI.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you; however,  you can adjust these parameters to get different results.
    `,
    imageName: use_case_4,
    template: 
   `You are an analyst at Skechers shoe company. You have been given the results of an survey related to Skechers.
    Read the survey data and write a narrative summary.
    Then based on the survey, draw actionable insights on
    1. Employee Satisfaction
    2. Product Insights
    3. Actions to be taken to improve Employee satisfaction
    4. Actions to be taken to improve the Product

    <text>
    {{PROMPT}}
    </text>
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    title: 'Investor Relations',
    key: 'investor_relations',
    environments: ['*'],
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to instantly review public earnings documents from competitors or peers and extract the following information:
    <ul class="list-disc mx-6">
    <li>Company KPIs</li>
    <li>Segment KPIs</li>
    <li>Segment Summary</li>
    <li>Key Topics</li>
    <li>Frequent Topics</li>
    <li>Forward Guidance</li>
    <li>Transcript Analyst QA Summary</li>
    </ul>
    <br/><br/>
    <h2 class="font-bold">Instructions</h2>
    <ul class="list-decimal mx-6">
    <li>Upload a document; transcript, sell-side analyst report, or press release (in PDF or Word format) using the paperclip icon.</li>
    <li>Follow the instructions on the prompt that appears once the document has uploaded.</li>
    <li>Instruct the GenAI model to create one of the listed reports in a new prompt by typing in "Generate [insert report type here]" in the chat box, then hit enter/send button.</li>
    <li>Wait for GenAI model to produce a response.</li>
    <li>Some of the reports require you to copy/paste a list of items into the chat window as a 'Step 2' after asking to generate the report; make sure to paste a delineated (comma) or enumerated list of items/topics/segments.</li>
    </ul>
    We've preset configuration parameters on the right-hand side for you; however,  you can adjust these parameters to get different results.
    `,
    imageName: use_case_1,
    template: `
    You are an investment analyst who is reviewing various company earnings documents that I will provide to you. Your job is to prepare reports for me about the documents.
 
    In order to do so you will need to ask me what type of report to prepare. The available options are as follows. Please Show these options back to me when asking which one I'd like to use:
    
    - Company KPIs
    - Segment KPIs
    - Segment Summary
    - Key Topics
    - Frequent Topics
    - Forward Guidance
    - Transcript Analyst QA Summary

    Whenever you show the list to me, please format it as a bullet list.
    
    Here are instructions for you to follow to prepare your report for each report type:
    
    ### Company KPIs:

    Extract all financial KPIs reported in the text that match 'Quarterly' (current quarter) and 'Annual' (current fiscal year).  Also, provide the quote for 'Quarterly' and 'Annual' from the text where the KPI was found.
    
    Rules for output:
    
    1. Each value in a sentence should be extracted independently. If both revenue and revenue growth KPIs are in the same sentence, return both KPIs.
    
    2. Exclude forward guidance KPIs (expected/anticipated future financial performance).
    
    3. Exclude segment-specific KPIs.
    
    4. Ensure each revenue value scale is included as applicable, and always use the abbreviation; (M=millions, B=billions, etc.).
    
    5. Respond in table format with the following column names; “KPI Name”, "Quarterly Value", “Quarterly Quote”, "Annual Value", and “Annual Quote”.  If there is no value for a KPI at a given frequency, use "Not found" in the cell. 
    
    6. Do not provide any additional lead in or follow up text in addition to the formatted table requested.
    
    ### Segment KPIs
    
    First, you need to ask me for a list of segments that I'm interested in for this report. Call this list #KPI_SEGMENTS.
    
    Extract all financial KPIs reported in the text that match 'Quarterly' (current quarter) and 'Annual' (current fiscal year) for the following company segments:
    #KPI_SEGMENTS
    Also, provide the quote for 'Quarterly' and 'Annual' from the text where the KPI was found.
    
    Rules for output:
    
    1. Each value in a sentence should be extracted independently. If both revenue and revenue growth KPIs are in the same sentence, return both KPIs.
    
    2. Exclude forward guidance KPIs (expected/anticipated future financial performance).
    
    3. Include KPIs only for the company segments listed above.
    
    4. Ensure each revenue value scale (M, B, etc.) is included as applicable; Always use the abbreviation.
    
    5. Respond in table format with the following column names; “KPI Name”, "Quarterly Value", “Quarterly Quote”, "Annual Value", and “Annual Quote”.  If there is no value for a KPI at a given frequency, use "Not found" in the cell. 
    
    6. Do not provide any additional lead in or follow up text in addition to the format requested. 
    
    
    ### Segment Summary
    
    First, you need to ask me for a list of segments that I'm interested in for this report. Call this list #SUMMARY_SEGMENTS.
    
    Extract all text from the document relating to each of the segments in the following list; 
    #SUMMARY_SEGMENTS
    Rules for output:
    1. Respond in a table format with the following columns; "Segment Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a segment, say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested
    
    ### Key Topics
    
    First, start by asking for a list of topics that I'm interested in for this report. Call this list #KEY_TOPICS

    Extract all text from the document that relates to each of the topics in the following list. 
    #KEY_TOPICS

    Use the relative frequency of mention for each topic to score the Mention Count as 'High', 'Medium', 'Low', or 'None'.
    
    Rules for output:
    
    1. Respond in a table format with the following columns; "Topic Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a topic, return a mention count of 'None' and say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested.



    ### Frequent Topics

    Automatically identify frequently mentioned topics within the document into a list #FREQUENT_TOPICS.

    Extract all text from the document that relates to each of the topics in the following list. 
    #FREQUENT_TOPICS

    Use the relative frequency of mention for each topic to score the Mention Count as 'High', 'Medium', 'Low', or 'None'.
    
    Rules for output:
    
    1. Respond in a table format with the following columns; "Topic Name", "Summary", and "Mention Count". Text under the “Summary” column should be in bullet point format.
    
    2. If no information is provided for a topic, return a mention count of 'None' and say "No information provided". 
    
    3. Do not provide any additional lead in text, follow up text, or numeric bullets in addition to the format requested.
    
    
    ### Forward Guidance
    
    Extract all of the forward guidance provided in the text.
    
    Forward guidance is defined as: Company projections/expectations for revenue, earnings, capital spending, and other financial performance KPIs.
    
    Rules for output: 
    
    1. 'Frequency' should be either 'quarterly', 'year-to-date', or ‘fiscal year’.
    
    2. Ensure each revenue value scale is included as applicable, and always use the abbreviation; (M=millions, B=billions, etc.).
    
    3. Respond in table format with the following column names; “KPI Name”, “KPI Value”, “Frequency”, and “Quote from Document”.  The column “Frequency” should stipulate whether the KPI is a quarterly, year-to-date, or a fiscal year metric. 
    
    4. Do not provide any additional lead in or follow up text in addition to the format requested. 
    
    ### Transcript Analyst QA Summary
    
    First, identify every sell-side institution who asked a question during the earnings call in the transcript.
    
    Next, one by one, for each sell-side institution, generate a table using the following rules that includes each question they asked the question.
    1. Respond in table format with the following columns; "Question Topic", “Question Summary”, "Company Response Summary”, and “Direct Quote”.
    2. “Question Summary” and “Company Response Summary” should be in bullet point format.
    3. “Direct Quote” should be an exact quote from the text that supports the “Company Response Summary” bullets.
    4. Do not provide any additional lead in or follow up text in addition to the format requested.
    
    Here is the report:
    <text>
    {{PROMPT}}
    </text>
    
    After you prepare and show me your report, I may ask follow-up questions about the document, or I may ask for a new report on the same document, or I may want to send you a new document to analyze. 
    
    `,
    config: {
      temperature: 0.7,
      topP: 0.9,
      topK: 50,
      maxTokens: 4096,
      stopSequences: [],
    }
  },

  {
    modelType: 'text',
    key: 'RAG_Kendra',
    title: 'RAG Test, Kendra, call transcript analysis',
    environments: ['none'],
    // terraform kendra index
    rag: {
      "method": "kendra",
      "identifier": "2966e512-ebe9-4f6b-8920-b020e26a3322",
      "template": ""
    },
    description: `
    Test against the Kendra index with a call transcript analysis.
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'RAG_kb_1',
    title: 'RAG Test, Bedrock Knowledge Base, call transcript analysis',
    environments: ['none'],
    // bedrock knowledge base
    rag: {
      "method": "kb",
      "identifier": "FUGHZPW8XR",
      "template": ""
    },
    description: `
    Test against the Bedrock Knowledge Base with call transcripts
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'RAG_KB_Security_Architecture',
    title: 'Security Architecture Knowledge Base',
    environments: ['dev'],
    // bedrock knowledge base
    rag: {
      "method": "kb",
      "identifier": "ZOIESTZLH3",
      "template": ""
    },
    description: `
    Ask security architecture related questions with knowledge supplied from Skechers documentation.
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.5,
      topP: 1,
      topK: 250,
      maxTokens: 2000,
      stopSequences: [],
    }
  },

//   {
//     modelType: 'text',
//     key: 'call_log_queries',
//     title: 'Test prepared & ad-hoc MongoDB queries',
//     environments: ['dev'],
//     reportParamsModal: RootCauseReportSelection,
//     description: `
//     Test using pre-set queries against call log data, or ad-hoc.
//     <br/><br/>
//     For an ad-hoc query, start your prompt with "query: "
// `,
//     imageName: use_case_1,
//     template: ``,
//     config: {
//       temperature: 0.5,
//       topP: 1,
//       topK: 250,
//       maxTokens: 2000,
//       stopSequences: [],
//     }
//   },

  {
    modelType: 'image',
    title: 'Idea Generation',
    key: 'idea_generation',
    description: 'Generate images to spark creativity.',
    imageName: use_case_3,
    template: "",
  },


  // Travel documents usecase
  {
    modelType: 'text',
    key: 'travel_queries',
    title: 'Travel Documents',
    environments: ['dev', 'prod'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "4Y8YSS0WND",
        "template": ""
      },
      prod: {
        "method": "kb",
        "identifier": "AHLOLWY2PZ",
        "template": ""
      }
    },
    description: `
    Use SkechAI to get relevant information about:
    <ul class="list-disc mx-6">
    <li>Concur Expense Guides</li>
    <li>Concur Expense New User Interface Training</li>
    <li>Duty of Care, International SOS</li>
    <li>Employee Travel Offers</li>
    <li>Global Travel and Meeting Policy</li>
    <li>Passports & Visas</li>
    <li>Skecher Corporate Credit Card</li>
    <li>Travel and Meeting Forms</li>
    <li>Travel Insurance Information</li>
    <li>Travel Resources and News</li>
    <li>Travel Vendors</li>
    <li>TripSource</li>
    <li>Virtual Meeting Resources</li>
`,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },

  // Employee Handbook Usecase
  {
    modelType: 'text',
    key: 'employee_handbook_queries',
    title: 'Employee Handbook US Corporate',
    environments: ['dev', 'prod'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "92M6KM1CYA",
        "template": ""
      },
      prod: {
        "method": "kb",
        "identifier": "YO0GNLOMJV",
        "template": ""
      }
    },
    description: `
    <h2 class="font-bold">Purpose</h2>
    Use SkechAI to retrieve important policies and guidelines outlined in the Skechers Employee Handbook.
    <br/><br/>
    <h2 class="font-bold">What's Covered</h2>
    You can ask SkechAI to provide information on the following topics:
    <ul class="list-disc mx-6">
    <li>Equal Employment Opportunity (EEO) and Anti-Discrimination Policies</li>
    <li>Harassment and Retaliation Prevention</li>
    <li>Reasonable Accommodations (Disability, Pregnancy, Religious)</li>
    <li>Work Schedules and Core Business Hours</li>
    <li>Overtime and Pay Policies for Non-Exempt Employees</li>
    <li>Leave of Absence and FMLA Policies</li>
    <li>Vacation, Sick Leave, and Personal Days</li>
    <li>Employee Conduct Rules and Disciplinary Procedures</li>
    <li>Employee Benefits, including Group Health Insurance and 401(k) Plans</li>
    <li>Safety and Workplace Security</li>
    <li>Reporting Procedures for Workplace Concerns</li>
    </ul>
    `,
    imageName: use_case_1,
    template: ``,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },

  // Retail Usecase
  {
    modelType: 'text',
    key: 'retail_rag',
    title: 'Retail Operations KB',
    environments: ['dev', 'sb'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "29R7R6RVRB",
        "template": ""
      },
      sb: {
        "method": "kb",
        "identifier": "29R7R6RVRB",
        "template": ""
      }
    },
    // description: `
    // <h2 class="font-bold">Purpose</h2>
    // Use SkechAI to retrieve information from Retail reference documents.
    // <br/><br/>
    // <h2 class="font-bold">What's Covered</h2>
    // You can ask SkechAI to provide information on the following topics:
    // <ul class="list-disc mx-6">
    // <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
    // <li>Nullam euismod, nisi vel bibendum tincidunt, velit nunc lacinia nunc.</li>
    // <li>Sed auctor, magna a bibendum bibendum, augue elit tincidunt urna.</li>
    // <li>Fusce vulputate sem at sapien dapibus porta.</li>
    // <li>Phasellus non tortor lacus. Suspendisse potenti.</li>
    // </ul>
    // `,
    imageName: use_case_1,
    template: `You are an AI assistant that answers questions about Skechers retail operations. Mention any sources, including page numbers, only at the end of your response. Here is the first question:
    <text>
    {{PROMPT}}
    </text>`,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'retail_product_rag',
    title: 'Retail Product KB',
    environments: ['dev', 'sb'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "RX6WCG7IYT",
        "template": ""
      },
      sb: {
        "method": "kb",
        "identifier": "RX6WCG7IYT",
        "template": ""
      }
    },
    // description: `
    // <h2 class="font-bold">Purpose</h2>
    // Use SkechAI to retrieve information from Retail reference documents.
    // <br/><br/>
    // <h2 class="font-bold">What's Covered</h2>
    // You can ask SkechAI to provide information on the following topics:
    // <ul class="list-disc mx-6">
    // <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
    // <li>Nullam euismod, nisi vel bibendum tincidunt, velit nunc lacinia nunc.</li>
    // <li>Sed auctor, magna a bibendum bibendum, augue elit tincidunt urna.</li>
    // <li>Fusce vulputate sem at sapien dapibus porta.</li>
    // <li>Phasellus non tortor lacus. Suspendisse potenti.</li>
    // </ul>
    // `,
    imageName: use_case_1,
    template: `You are an AI assistant that answers questions about Skechers retail products. Mention any sources, including page numbers, only at the end of your response. Here is the first question:
    <text>
    {{PROMPT}}
    </text>`,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  {
    modelType: 'text',
    key: 'retail_manager_rag',
    title: 'Retail Manager Guidance KB',
    environments: ['dev', 'sb'],
    rag: {
      dev: {
        "method": "kb",
        "identifier": "GCKVQ9781W",
        "template": ""
      },
      sb: {
        "method": "kb",
        "identifier": "GCKVQ9781W",
        "template": ""
      }
    },
    // description: `
    // <h2 class="font-bold">Purpose</h2>
    // Use SkechAI to retrieve information from Retail reference documents.
    // <br/><br/>
    // <h2 class="font-bold">What's Covered</h2>
    // You can ask SkechAI to provide information on the following topics:
    // <ul class="list-disc mx-6">
    // <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
    // <li>Nullam euismod, nisi vel bibendum tincidunt, velit nunc lacinia nunc.</li>
    // <li>Sed auctor, magna a bibendum bibendum, augue elit tincidunt urna.</li>
    // <li>Fusce vulputate sem at sapien dapibus porta.</li>
    // <li>Phasellus non tortor lacus. Suspendisse potenti.</li>
    // </ul>
    // `,
    imageName: use_case_1,
    template: `You are an expert in retail and are supporting the shoe company skechers. I have given you a set of data that highlights a couple key things. 1. 1-30 Store performance. 2. Key upcoming promotions 3. How to set up displays 4. How effective each of the stores (labeled 1-30) sets up displays in the past.
    Other Key Factors
    High performing stores need less guidance than poorly performing stores
    Stores that have had issues in the past setting up displays need more help
    Field managers need to attend each store at least once a month. But struggling stores could be attended up to 5 times per month. There should be no more than 5 stores that should be visited 5 times a month.
    There are 4 field managers Available for this. Pete, Martin, Ahmed, Elise
    <text>
    {{PROMPT}}
    </text>
    `,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
  // Shoe Naming
  {
    modelType: 'text',
    key: 'shoe_naming',
    title: 'Shoe Naming',
    environments: ['dev', 'sb'],
    description: `
    `,
    imageName: use_case_1,
    template: `You are an AI-powered assistant designed to support Skechers in managing and optimizing the entire product lifecycle, from initial concept to market launch and beyond. Your role includes facilitating the use of various GenAI tools such as Name Generation, Virtual Design, Simulated Focus Groups, Pricing Analysis, Market Estimation, Cost Simulation, Supply Chain Optimization, Marketing Simulation, ROI Calculation, Decision Support, and Continuous Improvement Loop. You provide insights, automate data analysis, and generate reports to aid in decision-making, product development, marketing strategies, and crisis management. Maintain a professional yet accessible tone, ensuring clarity and actionable advice in all interactions. Ask for necessary details before providing recommendations, especially when simulating market scenarios or analyzing consumer feedback.
      For generating brand-aligned sneaker names: 
      Generate 5 unique sneaker names that align with our brand's sporty and innovative image. For each name, provide:
      a) The name itself
      b) A brief explanation (15 words max) of how it reflects our brand values
      c) A suggested product category (e.g. running, casual, performance)

      Here is the user request:
      <text>
      {{PROMPT}}
      </text>
      
      Here is some Skechers brand information:

      Skechers USA (NYSE:SKX) Q2 2024 Earnings Conference Call July 25, 2024 4:30 PM ET
      Company Par�cipants
      Jarred Dahlerbruch - Senior Product Manager
      David Weinberg - Chief Financial Officer
      John Vandemore - Independent Director
      Conference Call Par�cipants
      Jay Sole - UBS Group
      Laurent Vasilescu - BNP Paribas
      Peter McGoldrick - S�fel
      John Kernan - TD Cowen
      Alex Straton - Morgan Stanley & Co
      Rick Patel - Raymond James
      Jesalyn Wong - Evercore
      Will Gartner - Wells Fargo Securi�es
      Krisz�na Katai - Deutsche Bank
      Chris Nardone - Bank of America Merrill Lynch
      Tom Nikic - Wedbush Securi�es
      Operator
      Gree�ngs, and welcome to Skechers' second-quarter 2024 earnings conference call. [Operator
      Instruc�ons]. As a reminder, this conference is being recorded. I would now like to turn the
      conference over to Sketchers. Thank you. You may begin.
      Jarred Dahlerbruch
      Howdy, everyone. Thanks for joining Skechers' second-quarter 2024 earnings conference call.
      My name is Jarred Dahlerbruch. I'm a Senior Product Manager on the Product Development
      team here at Skechers, and I've been with the companies since star�ng as an intern in 2017.
      My favorite style is the Snoop One OG sneaker from our Snoop Dogg collab. Also joining us on
      the call or Skechers' Chief Opera�ng Officer, David Weinberg, and Chief Financial Officer, John
      Vandemore. Before we begin, I would like to remind everyone of the company's Safe Harbor
      statement.
      Certain statements made on today's call contains forward-looking statements based on current
      expecta�ons, including without limita�on, statements addressing the beliefs, plans, objec�ves,
      es�mates, and expecta�ons of the company and its future results and certain events. These
      forward-looking statements involve known and unknown risks, uncertain�es, and other factors, 
      which may cause actual results to differ materially from such statements. There can be no
      assurance that the actual future results, performance or achievements expressed or implied by
      any of our forward-looking statements will occur.
      Please refer to the company's reports filed with the SEC, including its annual report on Form 10-
      K and quarterly reports on Form 10-Q. For more informa�on on these risks and uncertain�es
      that may affect the company's business, financial condi�ons, cash flows, and results of
      opera�ons.
      With that, I would like to turn the call over to Skechers' Chief Opera�ng Officer, David Weinberg.
      David Weinberg
      Good a�ernoon, and thank you for joining us today on our second-quarter 2024 conference call.
      The second quarter marked another sales record for the period. Sales were $2.16 billion, an
      increase of 7.2%, or $145 million compared to last year. On a constant currency basis, sales
      were $2.19 billion, an increase of 8.7%. Gross margins were 54.9%, a 220 basis point increase.
      We're also pleased to announce a new $1 billion share repurchase plan, which replaces and
      significantly enhances our exis�ng program. The record second-quarter sales are par�cularly
      noteworthy, given supply chain disrup�ons impac�ng shipments to Europe; a difficult and pricedriven 618 shopping event in China; and foreign currency headwinds. Strong global demand for
      our comfort and innova�ve products drove our record sales, resul�ng in growth across all
      regions and segments.
      The infusion of comfort technologies, such as Skechers Hands Free Slip-Ins within our diverse
      product offering, from the Snoop Dogg collec�on, and Skechers GO GOLF, to Skechers GO WALK,
      and Kids, resonated with consumers of all ages and interests. We have recently expanded this
      comfort and convenience feature to addi�onal product categories. Skechers Hands Free Slip-Ins
      is just one of our many comfort innova�ons, which also includes Skechers Arch Fit, Skechers
      Skechers Air-Cooled Memory Foam, Hyper Burst, and many more, all of which are part of our
      being, The Comfort Technology Company.
      We have successfully partnered with industry technology leaders like Goodyear to further
      enhance our product offering. We also announced a new partnership with John Deere. The
      footwear incorporates the iconic John Deere branding with Skechers Comfort Technologies, the
      perfect blend of innova�on and rugged style.
      In our performance category, we collaborated with our elite athletes and product testers to
      elevate the fit and technologies across the division. No mater where you train or compete, and
      regardless of your skill level, you can trust that you are equipped with comfort that performs.
      Recently, athletes competed on global stages wearing Skechers Football Boots, including Golden
      Boot winner Harry Kane for England, and Oleksandr Zinchenko for Ukraine, both at the Euros;
      and Bobby Red for Jamaica and Copa America.
      This weekend, we will con�nue to see athletes compe�ng. They include Skechers ambassador
      and Philadelphia 76-ers star, Joel Embiid; as well as Canadian golfer, Brooke Henderson; Bri�sh
      golfer, Mat Fitzpatrick; and Spanish racewalker, Diego García. American Beach volleyball duo,
      Andy Benesh and Miles Partain, will be playing in Skechers branded uniforms. The Malaysian
      Olympic team will also be wearing Skechers footwear during the opening ceremony and for
      daily use during the games.
      With football rolling out globally this month, our roster of athletes con�nues to grow, including
      recently signed West Ham rising star, Mohammed Kudus; Bundesliga striker, Ragna Aka; and
      Chilean defender Emiliano Amor.
      And with basketball rolling out globally next month, we are announcing the signing of WNBA
      and Los Angeles Sparks Rising Star Forward, Rickea Jackson. We see many more opportuni�es
      ahead as we bring Skechers basketball around the world.
      As the Comfort Technology Company, we priori�ze delivering the ul�mate in innova�on,
      comfort, and style, so that every pair looks and feels excep�onal. Whether you're working in an
      office, restaurant, hospital, or playing golf, basketball, or pickleball, Skechers will be your
      unwavering companion and comfort.
      We engage with diverse consumers through a comprehensive, mul�-pla�orm, 360-degree
      marke�ng approach. In the second quarter, this included the first Skechers football commercial
      starring Harry Kane, Skechers Uno campaigns with actress Ashley Park and German singer
      Vanessa Mai, Skechers apparel campaigns for men and women, and Skechers Hands Free Slipins with global, as well as regional talent.
      This quarter, we introduced a new Skechers football campaign with our team of elite athletes, as
      well as spots featuring Harry, with guest star, Snoop Dogg; and re�red English footballer, Jamie
      Redknapp. As we did for football, we have created dedicated social channels for Skechers
      basketball in prepara�on for the global launch in August. And we are in the process of crea�ng
      fresh campaigns with Joel and Rickea, as well as new campaigns with New York Knicks' Julius
      Randle, and LA Clippers' Terance Mann.
      As we con�nue to drive brand awareness and purchase intent and increase our product offering
      globally, we remain focused on building efficiencies within our business to scale for profitable
      growth.
      Looking at our second-quarter results, sales increased 7.2% to a new second-quarter record of
      $2.16 billion. On a constant currency basis, sales increased 8.7% to $2.19 billion. Domes�c sales
      increased 7.7%. Interna�onal sales increased 6.9% and represented 60% of our total sales. By
      region, Americas increased 7.2%, EMEA 14%, and APAC 2.2%.
      The quarterly growth came despite several macro headwinds. In the United States, traffic was
      down. And in China, economic challenges weaken consumer demand across mul�ple industries,
      especially over the 6-18 holiday period. In India, we navigated ongoing import regula�ons,
      which led to constrained inventory. We believe India is an extremely important market, and we
      are ac�vely addressing the regulatory hurdles by producing more product locally and leveraging
      our new 660,000 square foot distribu�on center in Mumbai.
      In addi�on, despite extremely strong demand in Europe, sales were shi�ed to the second half of
      the year by increased transit �me. This created a short-term imbalance between on-hand
      inventory, which was down about 40%, and in-transit inventory, which was up over 150%.
      Our wholesale sales increased 5.5%, driven by domes�c growth of 14%, which was the result of
      double-digit increases in our men's and kids' footwear, as well as growth in women's and
      improvements in volume and ASPs. Interna�onal wholesale was flat compared to last year,
      primarily due to the aforemen�oned challenges in China and India.
      Direct-to-consumer increased 9.2%, resul�ng in sales of more than a billion dollars for the
      quarter, a first for the company. This growth was primarily due to an increase of 15%
      interna�onally, with improvements in most markets for both our brick and mortar and ecommerce stores.
      Domes�c direct-to-consumer sales improved 1.4% as we faced a par�cularly strong comp with
      growth of 29% in the second quarter of 2023 and the reported so�er retail store traffic across
      the country. Direct-to-consumer con�nues to be a key segment of our business and an indicator
      of posi�ve consumer appe�te for our brand.
      We ended the quarter with 5,267 Skechers branded stores worldwide, of which 1,702 are
      company-owned loca�ons, including 576 in the United States. We opened 71 company-owned
      stores in the quarter, including 27 in China, 15 big-box loca�ons in the United States, 6 in
      Vietnam, and 5 in Germany. We closed 40 stores in the quarter.
      Also in the period, 104 third-party stores opened, including 56 in China, 8 in Indonesia, 7 in the
      Philippines, and 3 in India. This brings our third party store count at quarter end to 3,565. In the
      third quarter to date, we have opened 13 company owned stores, including 3 big box stores in
      the United States and 3 in Mexico. We expect to open an addi�onal 140 to 150 company-owned
      stores worldwide over the remainder of 2024.
      Investments across our direct to consumer business, product offering, demand crea�on, and
      infrastructure remain priori�es, including the expansion of our distribu�on center in Panama,
      which serves mul�ple countries in La�n America and is now opera�onal, and our new companyowned DC in Colombia, which opened this month. We con�nue to focus on making our
      products available where consumers want to shop, be it at our retail and e-commerce stores or
      at one of our many wholesale and franchise partner loca�ons around the world.
      We're looking forward to the second half of 2024, as we con�nue to scale our business
      worldwide and reach our goal of $10 billion in annual sales in 2026.
      And now, I would like to turn the call over to John for more details on our financial results.
      John Vandemore
      Thank you, David, and good a�ernoon, everyone. Skechers delivered record second quarter
      sales of $2.16 billion, growing 7.2% year over year, driven by con�nued strength in our
      interna�onal direct-to-consumer business and significant improvement in our domes�c
      wholesale business. While strong, these results were below expecta�ons, due in part to severe
      foreign currency exchange headwinds in the quarter.
      On a constant currency basis, sales were more in line with our expecta�ons growing 8.7% to
      $2.19 billion. Earnings per share in the quarter of $0.91. $0.97 on a constant currency basis
      exceeded our expecta�ons, reflec�ng con�nued strong gross margins.
      Despite naviga�ng these and other headwinds from the supply chain, regulatory obstacles in
      India and a lackluster 618 holiday in China, we are encouraged by the con�nued posi�ve
      response to our comfort technologies from consumers. As we will discuss later, we have
      improved visibility into the second half of the year and are adjus�ng up our full year guidance as
      a result.
      Turning to direct to consumer, sales grew 9.2% year over year and exceeded $1 billion for the
      quarter, a first in our company's history. Growth was driven by con�nued strength
      interna�onally, which rose 15%, including double-digit growth in both our physical retail and ecommerce channels, and followed impressive prior year growth of 30%.
      Domes�c direct-to-consumer sales grew 1.4% as we faced a difficult comparison to last year's
      29% increase. Consistent with broader market trends, we observed lighter foot traffic in our
      brick-and-mortar loca�ons in the quarter, but marked improvements in our e-commerce
      channel.
      Global demand for Skechers products remains strong, and consumers are purchasing across a
      broad range of price points, which speaks to the enduring appeal of our focus on delivering
      style, comfort, and quality at a reasonable price. The Skechers brand con�nues to build 
      momentum in the market and the expansion of our global direct-to-consumer footprint remains
      a key priority for driving long-term growth.
      In wholesale, sales increased 5.5% year over year to $1.13 billion. Domes�c wholesale sales
      grew 14%, or $56 million versus the prior year, reflec�ng strong consumer demand for our
      product and robust order flow, a trend we see con�nuing in the second half of the year. Our
      interna�onal wholesale sales were essen�ally flat, as pockets of strength in many markets were
      weighed down by so�er results in select markets like India and China. In addi�on, supply chain
      disrup�ons from the Red Sea crisis nega�vely impacted our business in Europe, with deliveries
      shi�ing into the second half of 2024.
      Now turning to our regional sales. In the Americas, sales for the second quarter increased 7.2%
      year over year to $1.1 billion, driven by domes�c wholesale, which accounted for over half the
      growth.
      The Americas direct-to-consumer business grew across all markets, including double digit
      growth outside of the United States. While the macro environment remains challenging with
      pressures on discre�onary spending, Skechers commitment to delivering high-quality products
      at reasonable prices is resona�ng with consumers.
      In EMEA, sales increased 14% year over year to $492.5 million, driven by strong performance in
      our direct-to-consumer business with double-digit growth across channels. Wholesale sales
      were so�er than an�cipated due to the aforemen�oned supply chain disrup�ons. We an�cipate
      improvements in these delays over the course of the year, but we are con�nuing to closely
      monitor the situa�on and will provide further updates as warranted.
      In Asia Pacific, sales increased 2.2% versus the prior year to $564.2 million. In China, sales grew
      3.4% year over year, 7% on a constant currency basis. We believe that China's economic
      recovery will remain challenged in the near term, but we are confident in the long-term
      opportunity for Skechers, given the strong consumer percep�on and demand for our brand in
      the market.
      In India, sales were nega�vely impacted by the implementa�on of new regulatory standards and
      other unfavorable market condi�ons. More recently, we have seen posi�ve developments
      around the regulatory environment, and our efforts are focused on prudently naviga�ng the
      near term, while con�nuing to prepare for the long-term opportunity we believe this market
      possesses.
      Gross margin was 54.9%, up 220 basis points compared to the prior year. The improvement was
      primarily driven by lower freight costs and a favorable mix of direct to consumer volumes.
      Opera�ng expenses increased 340 basis points as a percentage of sales year over year to 45.3%.
      Selling expenses as a percentage of sales increased 160 basis points versus last year to 10.9%.
      As men�oned last quarter, this spending was largely focused on brand building investments and
      heightening awareness of our innova�ve comfort technologies in new categories.
      General and administra�ve expenses increased 180 basis points as a percentage of sales to
      34.4%, primarily due to higher rent, deprecia�on, and labor to support growth in our direct to
      consumer segment and compensa�on-related costs, par�ally offset by cost efficiencies realized
      in our distribu�on centers.
      Earnings from opera�ons were $206.5 million, a decrease of 5.1% compared to the prior year.
      And opera�ng margin for the quarter was 9.6% compared to 10.8% last year, primarily due to
      investments in brand building and global expansion.
      Our effec�ve tax rate for the second quarter was 19.7% compared to 17.7% in the prior year.
      Earnings per share were $0.91 per diluted share, a 7.1% decrease compared to the prior year on
      154.2 million weighted average diluted shares outstanding.
      On a constant currency basis, earnings per share were essen�ally flat at $0.97 per diluted share.
      And now turning to our balance sheet items. Inventory was $1.51 billion, an increase of 1.9% or
      $28.5 million compared to the prior year. However, as David men�oned, supply chain delays
      created a short-term imbalance between on-hand inventory, down 18%; and in-transit
      inventory, which was up nearly 100%. Overall, the composi�on of our inventories are healthy,
      and we believe this imbalance will be remedied over the course of the next quarter.
      Accounts receivable at quarter end were $1.03 billion, an increase of $87 million compared to
      the prior year, reflec�ng higher wholesale sales. We ended the quarter with $1.55 billion in
      cash, cash equivalents, and investments, and maintain liquidity of over $2.3 billion when
      including our undrawn revolving credit facility.
      Capital expenditures for the quarter were , of which $47.9 million related to investments in new
      store openings and direct-to-consumer technologies, $37.4 million related to the expansion of
      our distribu�on infrastructure, and $12.4 million related to the construc�on of our new
      corporate offices.
      Our capital investments are focused on suppor�ng our strategic priori�es, which include
      growing our direct consumer segment and expanding our brand presence globally.
      During the second quarter, we repurchased approximately 879,000 shares of our Class A
      common stock at a cost of $60 million. And today, we are announcing a new $1 billion threeyear share repurchase authoriza�on, which replaces our exis�ng program. We con�nue to 
      deploy our capital consistent with our stated philosophy while maintaining a durable balance
      sheet and ample liquidity.
      Now, turning to guidance. For the full year 2024, we expect sales in the range of $8.875 billion
      to $8.975 billion, and earnings per diluted share in the range of $4.08 to $4.18, represen�ng
      annual growth of 12% and 18% respec�vely at the midpoint.
      For the third quarter, we expect sales in the range of $2.3 billion to $2.35 billion, and earnings
      for diluted share in the range of $1.10 to $1.15. Our effec�ve tax rate for the year is expected to
      be between 19% and 20% and minority interest is expected to grow in line with total sales.
      Capital expenditures are an�cipated to be between $325 million and $375 million for the year.
      We remain commited to achieving $10 billion in sales by 2026 and delivering long-term,
      sustainable, and profitable growth.
      We thank you all for your �me today and look forward to upda�ng you on our third-quarter
      financial results, which we expect to release on Thursday, October 24.
      With that, I will now turn the call over to David for closing remarks.
      David Weinberg
      Thank you, John. Despite the recent challenges, we achieved a new second-quarter sales record
      with growth in both our wholesale and direct-to-consumer business across the globe. This
      reflects the strong and broad-based acceptance of our products and our commitment to
      delivering the best and comfort innova�on, style, and quality at a reasonable price. As we
      navigate the challenges ahead, including the transit delays due to the Suez Canal closures, and
      the regulatory changes in India, we see numerous opportuni�es to expand our business and are
      extremely encouraged by the demand for our brand.
      We are excited about the ongoing launch of Skechers Football and the global launch of Skechers
      Basketball. Recognizing that consumers want to shop for Skechers how, where, and when they
      want, we remain commited to growing our direct-to-consumer channel, while also focusing on
      increasing our important rela�onships with a third-party customers.
      Going into the third quarter, we are tracking stronger than last year and believe the second half
      we'll be above our ini�al expecta�ons. As always, we are grateful for the contribu�ons of the
      en�re Skechers organiza�on and our valuable progress, as we deliver profitable growth this year
      and into the future.
      Now, I would like to turn the call over to the operator for ques�ons.
      Ques�on-and-Answer Session
      Operator
      [Operator Instruc�ons]. Our first ques�on comes from Jay Sole with UBS.
      Jay Sole
      I want to ask about the guidance. It sounds like FX and supply chain were a litle bit headwinds
      in the quarter impacted sales and earnings, maybe rela�ve to what you thought. But yet you're
      raising the sales guidance and the EPS guidance. Can you just explain and dive into a litle bit
      the sources of the raise and the guidance? What's causing you to raise the sales guidance, and
      specifically what's causing you to raise the EPS guidance given it sounds like these headwinds
      are s�ll con�nuing?
      John Vandemore
      Well, hello, Jay. I would say the number one thing is the beter visibility we have into the back
      half order book, par�cularly on the wholesale side of a business and drilling down a bit from
      there on the domes�c wholesale side, where we see really strong order flow. I'd couple that
      with the ramifica�ons of what we've seen on the supply chain side, delaying deliveries to our
      distribu�on func�on in Europe s�ll represent very good orders that are flowing into the back
      half of the year. So that's augmen�ng the strength we've already had.
      We con�nue to see very good DTC performance, interna�onally. Like a lot of others, we did see
      some traffic declines domes�cally, although our e-commerce pla�orm performed nicely in the
      quarter. So, taking it all together, quite frankly, we simply have beter visibility now. We've got a
      very nice order book built for domes�c and interna�onal wholesale. We're mindful of the
      challenges that are out there, some of which may persist to one degree or another in the back
      half, but we believe we've adequately weighted that in the range of outcomes we could expect.
      Jay Sole
      Got it. So, I mean, I guess just to follow up on that, John, you're saying that whether it's
      headwinds coming from FX or supply chain -- that's not really -- you're not really assuming those
      things alleviate in the back half. I mean, you're kind of assuming that there's some macro issues
      that are out there that persist, but yet you s�ll feel confident raising the guidance given the
      visibility of the order book and given the acceptance you're seeing from the consumer for the
      product assortment, broadly speaking.
      John Vandemore
      Well, we wouldn't raise the guidance unless we were confident in our ability to achieve it. I do
      think some of the macroeconomic headwinds we've seen will persist to one degree or another.
      And we have those adequately captured, we believe, in our budget.
      I'd say the one outlier is foreign currency. It was par�cularly acute during the second quarter
      and has already turned around, but that did not save us in the quarter. If you strip that out, as
      we men�oned, we would have been within our guidance range. That was kind of the straw that
      broke the camel's back on achieving our prior guidance, but the underlying consumer demand
      is s�ll there. And that's what's evidenced in the order book in the back half of the year and,
      again, the con�nua�on of what we've seen on the strength of DTC interna�onally.
      Jay Sole
      Fantas�c. Okay. Thank you so much.
      John Vandemore
      Sure.
      Operator
      Our next ques�on comes from Laurent Vasilescu with BNP.
      Laurent Vasilescu
      Good a�ernoon. Thank you very much for taking my ques�on. I wanted to ask about the
      comments around USGTC, around foot traffic, but then e-commerce being strong. There's a lot
      of concerns out there around just the overall environment over the summer in the footwear
      retail landscape.
      Maybe David, John, if you guys can comment about what you're thinking of seeing with the
      consumer. Is it weakening? Or is it just kind of a blip and then we can kind of see reaccelera�on
      for the third quarter for back to school?
      John Vandemore
      Well, I'd admit it was a bit of an odd quarter. We did see some of the traffic slow down in our
      brick-and-mortar stores. That definitely had an effect. At the same �me, though, our ecommerce pla�orm did really well in the quarter.
      I think it's also important to recognize, we're ge�ng back into a posi�on vis-à-vis our wholesale
      customers, where they are beter equipped with the right type of inventory. So I think when you
      think about the broader US market, clearly there's abundant strength at the consumer.
      The last thing I'd note, which we men�oned in our comments is, we had an incredibly strong
      prior year, nearly 30% up on DTC. And so, just maintaining that growth, if you look at it on some
      of your favorite two-year stack basis, it is s�ll an incredible to your growth rate.
      So where we go from here, I think, is going to be largely determined by what we see in the backto-school window and then holiday. I would characterize our expecta�ons as modest at this
      juncture. We're not overweigh�ng an expecta�on of domes�c reaccelera�on. But we also think
      there's plenty of consumer demand out there, as is evidenced by what we're seeing in e-comm
      and in the wholesale order book.
      Laurent Vasilescu
      Okay, very helpful. And then can you talk about the shi�? Is it fair to assume a $50 million shi�
      between 2Q and 3Q? Thank you very much.
      John Vandemore
      Well, it's a fair ques�on. I will say it's a litle bit challenging to answer only because, I mean, this
      has been an effect felt over the course of �me. So it wasn't like one ship missed a window,
      right? It's tough for us to quan�fy, but I think you can assume, vis-à-vis guidance for Q3 in
      par�cular, and just the known aspect of what's been impac�ng supply chain, par�cularly that
      Asia to European route, is that a material amount of orders moved into the second half of the
      year, simply because they couldn't get the distribu�on in �me to make the quarter.
      And keep in mind, there's always a litle bit of that as we straddle Q2 and Q3, depending upon
      when shipments go. What I would say is, absolutely concrete is the demand. The demand we're
      feeling for the product, as I said, it's evidenced in the order books globally. And so we feel very
      good about finalizing out those orders. And the normaliza�on of the supply chain that we
      expect is going to occur over the next quarter or two.
      Laurent Vasilescu
      Very helpful, John. And I think last quarter, you had -- I think to my good friend's poser's
      ques�on, you men�oned, gross margins could be -- I know you don't guide gross margins, but
      they can be up 100 to 150 bps. Is that s�ll the right way to think about it? And if so, how do we
      think about three-key gross margins?
      John Vandemore
      Well, I would say -- I mean, this quarter was a litle bit more than we an�cipated. We picked up
      more of a benefit from freight and mix than we had an�cipated. As we had said previously, we
      expect the benefit to get smaller over the course of the year. It did a litle bit, but this was even
      a bit higher.
      I would say, you know, we don't expect a lot more out of the balance of the year, but for mixrelated. Although, we are watching freight rates, we'll have to keep in mind that as we progress 
      throughout the year. There's been some rate impact, obviously, from the Red Sea crisis. We
      have to balance that with the contractual rates that we're achieving, and so we'll monitor that.
      But when you put all that into the model, ul�mately it would tell you that we don't expect as
      much li� over the back half of the year as we saw over the front half of the year though that is
      consistent with what we had previously men�oned.
      Laurent Vasilescu
      Very helpful. Best of luck.
      John Vandemore
      Thank you.
      Operator
      Our next ques�on comes from Jim Duffy with S�fel.
      Peter McGoldrick
      This is Peter McGoldrick on for Jim. Thanks for taking our ques�on. First, I wanted to ask about
      the BIS regula�ons in India. You men�oned some local produc�on and distribu�on. What's the
      magnitude of the local supply capacity rela�ve to demand? And how are you planning for the
      progression of the regulatory environment, sort of bridging the gap, the �ming to bridge the
      gap between near-term impacts and the long-term opportunity in that market?
      John Vandemore
      The first thing I'd say is there was a no�ceable impact from the regulatory environment in India
      this quarter. That had a significant effect on our, in par�cular, our Asia-Pac sales. So we
      definitely felt that and the atendant uncertainty in the quarter.
      The good news is, we con�nue to build local produc�on. I won't give a percentage, but suffice it
      to say it's one of our primary areas of focus from a supply chain perspec�ve, and it's ge�ng
      beter and beter. It's simply, today, insufficient to accommodate our total demand.
      We have seen some posi�ve trends in the market with regards to cer�fica�on processes, both
      of domes�c and interna�onal manufacturing. I would expect over the course of the year, things
      con�nue to get beter, but that's a market that's a litle bit tough to call from a �ming
      perspec�ve on when things are going to change.
      But overall, we con�nue to be op�mis�c, both about the back half of the year, but ul�mately,
      that we will be able to, as a company, significantly develop what's needed locally, but then 
      complement that with interna�onal manufacturing and maybe even someday look towards
      India as an export produc�on market for us.
      So it had an impact, a big impact in Q2. We believe that will get significantly beter over the
      back half of the year. And again, I can't stress enough, we have definitely seen some posi�ve
      trends of late. And that has been encouraging as well.
      Peter McGoldrick
      All right, thanks. Then I'd like to follow up on China. Revenue increased 3% with, despite the
      challenges noted on the 618 period. Can you talk about the trends outside of the key holiday
      periods and any consumer insights that might influence plans for the rest of the year and your
      plans for 11-11? And should we be looking for growth in the second half out of China?
      John Vandemore
      We're definitely expec�ng growth in the second half. And I would argue that the first half of this
      quarter actually was prety encouraging. It's also important to note they faced a really
      significant foreign currency headwind in this quarter. With -- I think constant currency sales
      were double the growth rate of what we saw on a realized currency basis.
      618 was definitely not nearly as strong as we've come to expect over �me. It's hard to read that
      through to the balance of the year because 618 is such a unique event, and it's very
      promo�onal. As David men�oned, we saw a lot more price-driven ac�vity on 618 this year. And
      so that simply compelled a lot less growth overall than we would have liked to have seen.
      As we also said -- we con�nue to think China is on the road to recovery. We expect a beter
      second half of the year than what we've seen thus far, but we are watching things carefully.
      Double 11 will certainly be a big event in the context of how that market is recovering.
      Again, I think the most encouraging aspect of what we've seen there is con�nued brand
      resonance and I think outperformance rela�ve to some other interna�onal brands, which I think
      speaks to the appeal of Skechers in the market, which we expect to con�nue to ride for the long
      term.
      Peter McGoldrick
      Very helpful. Thank you.
      Operator
      Our next ques�on comes from John Kernan with TD Cowen.
      John Kernan
      Excellent, thanks. Good a�ernoon, John and David. Hope all is well out on Manhatan Beach.
      Maybe unpack the guidance increase for the back half of the year a litle bit more. Is there
      anything specifically from a channel or geographical perspec�ve that you have clear line of sight
      that's going to accelerate from where we were in Q2? It sounds like interna�onal DTC, you've
      got good reach on and globally and wholesale, but just a litle more color there would be
      helpful.
      John Vandemore
      I think that I think the best characteriza�on we can give is con�nued strength from DTC. We're
      going to pick up the benefit of the �ming issue from the Suez Canal crisis in Europe on the
      wholesale side as well. And then the domes�c wholesale order book is very strong. I'd say those
      are probably going to be the lead factors for the back half of the year growth.
      I think also just the absence of some of the headwinds that we saw this quarter, par�cularly
      around some of the regulatory strength issued in India, the foreign currency, I think that that
      probably makes up for most of the growth. But I would also say we've contemplated some of
      the other issues. We feel like we waited them appropriately. We don't expect it to be fairly
      smooth sailing from here. But I do think the unique combina�on of events this quarter made it
      a bit more challenging than anyone had an�cipated going in. And that's part of the reason why
      our ini�al kind of non-constant currency sales were below where we thought they would be.
      David Weinberg
      I think it's fair to point out at this point that the shi�s from June and July and the shi�s from
      December and January that we talk about every year are just more extreme in this par�cular
      case, simply because the biggest part of our shipments for the most part for domes�c and
      domes�c wholesale and European wholesale is the end of June and the beginning of July.
      And while Johnson is very difficult to see what went early, what went late, we're ge�ng a beter
      flow even though it takes a longer �me to get there from Asia into Europe. So you can pick up
      June to July, as in December to January we commented this year. Well, that doesn't guarantee
      the whole quarter. It's a great place to start, and we see demand picking up in a number of
      those places.
      And also, on the US side, just to reiterate what John said, because I think it's very important,
      given a 29%, 30% increase in our direct-to-consumer business last year, to hold that while we
      have a 14% increase in domes�c wholesale and have increasing demand and in a difficult �me,
      just shows the strength of the consumer has shi�ed from just one to the other and picked up
      some new consumers; some go direct to consumers, some go to their favorite wholesale
      partners that we have.
      So the overall business con�nues and when you think about it, we pick up a wholesale sale and
      direct to wholesale in the US. So there are more unit growth with the 14% in wholesale, then it
      would have been indirect to consumer. So that shows more demand just going to a different
      place. I think both things line up very well for us, which is a significant piece of the increase in
      the guidance into the third quarter.
      John Kernan
      That's really helpful, thanks. Maybe a quick follow up on the supply chain costs and some of the
      �ming issues. It looks like spot freight rates from an ocean perspec�ve have skyrocketed the last
      couple months. No, you don't buy on do your contracts on spot, but how do we think about a
      different freight cost environment as we get into maybe Q4 and 2025? Do you see this as a
      headwind?
      John Vandemore
      Well, John, I think you first point out a very important factor, which is we don't expect to be
      feeling the effects of some of the higher spot rates un�l Q3 and probably more acutely in Q4,
      simply because of the �me it takes to turn the inventory.
      Also we're not, we're not buying, as you noted, everything at spot. You know, there certainly are
      opportuni�es and needs we have to go into the spot market. So our weighted average container
      rate is well below where the spot sits.
      There's some other factors at play that we think will help offset some of that, but at the end of
      the day, un�l we see a culmina�on of that increase, and quite frankly, an improvement overall in
      both the flow of goods, as David men�oned, but also in the rates which we expect is
      forthcoming, it's tough to call the final outcome other than, right now, we do believe we've
      incorporated that into our guidance.
      It's one of the reasons why, in response to Laurent's ques�on, we were cau�ous about gross
      margin improvement from here. We believe it'll be rela�vely consistent improvement or
      consistent to last year. And that's why, because some of that freight will come into play.
      John Kernan
      Understood, thanks. Then maybe, David, just one follow up on the customer acquisi�on. Can
      you talk to the cohorts you are acquiring, some of the growth in the newer categories you've
      launched recently and how they're performing?
      David Weinberg
      Sorry, say that again? I missed it.
      John Kernan
      Can you talk to customer acquisi�on and some of the cohorts acquiring with some of the newer
      product launches?
      David Weinberg
      It's hard to tell. Our biggest push right now, really, is not moving out to the consumer, and that's
      on our performance athle�c. And we're just going to launch our first football slash soccer in
      Europe and are moving to a more commercial sign of basketball. I think what's happening is that
      our features and our comfort are expanding the base of our exis�ng customers, and we're
      acquiring from other brands just along our normal mix. We're s�ll looking forward to achieving
      significantly higher acquisi�on, as we get into more performance athle�cs, but we're just very
      beginning of that.
      John Kernan
      Understood. Thanks.
      Operator
      Our next ques�on comes from Alex Straton with Morgan Stanley.
      Alex Straton
      Perfect. Thanks a lot for taking the ques�on. I just wanted to focus on interna�onal wholesale.
      Obviously, it slowed a litle bit quarter over quarter, but seems like from your commentary,
      some of that's just temporary from issues like the Suez Canal, but then you're also not as
      posi�ve on China. It sounds like as you were maybe three months ago on a near-term basis.
      So can you just talk a litle bit about how we should think about the shape of the back half?
      What type of growth you're expec�ng there?
      John Vandemore
      So yeah. I would say, I think Q2 was a bit dis�nct in some of the impacts we felt, par�cularly on
      the interna�onal wholesale side of things. We men�oned Europe, we men�oned India, and
      those were -- they had an outsized impact on our results versus our original expecta�ons.
      I would s�ll characterize our view on China as a net posi�ve. Certainly, we expect growth in the
      year. And as we've said about China over the last couple of years, we've been somewhat
      surprised at the rather consistent improvement on abated we had seen.
      We know it's a market in recovery. We know there are some macro challenges. So again, I don't
      know that this outcome this quarter is par�cularly unan�cipated in the grand view. But
      obviously, we didn't pick the �ming right. And that's why you saw that.
      But I would also note, again, there's a big foreign currency adjustment on the Chinese sales.
      They would have been double the growth, which was more in line with where we had seen
      recent quarter over quarter kind of improvement. So again, I would characterize China, certainly
      as a market we have con�nued op�mism for.
      We do expect there will be bumps in the road. This was one of them, but it doesn't diminish in
      any way our appe�te to con�nue to invest in the market and the opportunity we think that
      market presents.
      Alex Straton
      Great, maybe one quick follow up just on selling expenses. I knew they grew quite a bit this
      quarter, I think about 25%. How should we think about that into the back half? I know you had a
      lot of demand crea�on expense in the quarter, should that start falling off or what are the puts
      and takes there?
      John Vandemore
      We'll con�nue to invest over the balance of the year, but the level of increases we do not expect
      will be similar. And that will con�nue to be the case in the next quarter and in the next quarter.
      And then coupled with some of the �ming related issues we just talked about, it certainly was a
      more severe point of deleverage on the quarter that we had originally expected. We believe
      some of that will get made up of over the back half of the year now that we've seen some of
      those sales move around and more strength and visibility into that back half growth that we've
      talked about.
      Alex Straton
      Great. Good luck, guys.
      John Vandemore
      Thank you.
      Operator
      Our next ques�on comes from Rick Patel with Raymond James.
      Rick Patel
      Good a�ernoon. I was hoping you can dig further into your expecta�ons for wholesale for the
      year. So I believe in the past you've alluded to global wholesale, being able to grow in the high
      single-digit range. Do you s�ll see this as a reasonable outcome, just given the strength you
      have on the domes�c side?
      And then just as a follow up, how far out is your line of sight for the wholesale order book as
      you think about domes�c versus interna�onal?
      John Vandemore
      So I definitely think that expecta�on for the full year results for global wholesale is accurate and
      probably, in all honesty, based on what we see at the moment, probably more likely to be at the
      low end of the range. But definitely con�nue to see good opportunity on the global wholesale
      side of things.
      From an order book perspec�ve, we feel really good about what we see. I think the only factors
      we need to keep our eye on is �ming, as kind of be able to -- this quarter in Europe in par�cular.
      But overall, I would say condi�ons are improving. As David men�oned, the flow of goods is
      becoming more reliable and more predictable, which always helps.
      So again, we would not have raised the guidance, we would not be speaking par�cularly about
      the strength we see if we hadn't the benefit of some very strong order book ac�vity. And that's
      certainly the case.
      Rick Patel
      And can you also talk about sourcing? Maybe remind us how much exposure you have to China.
      And just given the headlines everyone's saying about poten�al tariffs, and whether they may or
      may not increase down the road, just how we should think about mi�ga�on strategies that you
      may be working on right now to deal with that in the future?
      John Vandemore
      Yeah. There's not really been a fundamental change to our overall sourcing footprint, which
      we've commonly described as kind of being, depending on the �me period, you know,
      somewhere in the 40%-ish range for China, 40% range in Vietnam, and then the balance kind of
      spread across a lot of other countries. But that can ebb and flow depending upon what we're
      making when.
      I would also point out within that, you know, obviously, we have a prety significant business in
      China, so there's an element of what gets manufactured in China that serves the local market
      quite well.
      I would also note that we con�nue to look for opportuni�es to diversify our produc�on. The
      biggest challenge there is, quite frankly, the rate of growth we're seeing in our unit volumes. So
      we have to run just to keep things sta�c. But as a result, we're seeing really good trajectory, as
      we men�oned, in India, Indonesia, some other markets, Turkey, Mexico.
      So again, it's something we'll con�nue to work on. I think, in response to broad hypothe�cals
      about tariffs and what may come, it's really difficult to react. I think the one thing that we've
      learned is, you can't really react to hypothe�cals, but you need to be very quick to react to
      actual results. And so we'll be poised to react should we need to, although I would note again,
      it's going to be limited by that footprint; and I'd say, the footprint of the footwear industry and
      apparel industry as a whole.
      I also would note that even if there is such a thing as a tariff impact somewhere down the road
      from whomever ascends to leadership in the United States, that's going to be a market-wide
      issue. That's not going to par�cularly impact just one company. And so our an�cipa�on is you're
      going to have to see quite a bit of adjustment across the industry, not just with one brand or
      another.
      Rick Patel
      Thanks very much.
      Operator
      Our next ques�on comes from Jesalyn Wong with Evercore.
      Jesalyn Wong
      Hi, thanks for taking the ques�on on just digging into the supply chain disrup�on to Europe,
      how come out of the if the flow of product already coming through in the third quarter? And if
      so, do we expect interna�onal wholesale at to see a big pickup on under process and how we
      evaluate risk off on conges�on leading to addi�onal cost into the quarter from that?
      And then just on China, I think you men�oned ex-China, it was up 7% in the quarter. Any early
      reach into training for July? And back half, do you think that's high single digit number host exFX into the second half? Thank you.
      John Vandemore
      On the supply chain, I think the best answer I can give you is an illustra�ve data point on
      inventories. And we men�oned this in our script. Our on-hand inventory levels in Europe at the
      end of June were down 40%. Our in-transit inventories were up over 150%. So it gives you a
      flavor of just how much got delayed into the quarter.
      We are seeing that flow improve a lot of that in transit. It's quite frankly already landed or is in
      the process of landing and will get processed through. And it is, again, one of the reasons why
      we're confident enough in the bookings to be able to raise the guidance. That will clearly
      manifest on the interna�onal wholesale side, par�cularly in Europe.
      Insofar as China is concerned, I don't want to get down to kind of country-level guidance, but I
      would say, from the get-go this year, we expected growth. We con�nue to see growth. We do
      know that there will be hiccups, given the recovering nature of that market.
      On a constant currency basis, the growth was, while not what we had come to expect or hope
      for, it was a solid high single-digit number. Whether or not that con�nues over the balance of
      the year is what we have to see. Nothing from a READ perspec�ve we can give you in July thus
      far, given how early it is, plus just the nature of that market and how it tends to recover a�er
      these big selling events.
      But again, our expecta�on con�nues to be for growth in the year. We are posi�ve on the longterm opportunity in the market. And I think we're going to con�nue to work both the product
      that is obviously resona�ng across the globe in that market, as well as tac�cs specific to the
      market, which have been paying off and we would expect will in the future as well.
      Jesalyn Wong
      Got it. Thank you.
      Operator
      Our next ques�on comes from Will Gardiner with Wells Fargo.
      Will Gartner
      Hey guys, thanks for taking my ques�on here. Just curious, digging into China a litle bit more,
      can you just talk a litle bit about how much of the China inventory is China for China versus
      imported to the US? Can you give us a sense of that?
      John Vandemore
      Well, it's not a sta�c number, is probably the biggest challenge to do that. But I mean, obviously
      with about 40%, 45% of our produc�on coming from that market, and a mid-teen percent of
      our overall sales coming from that market, you can see it's no small quantum of goods. It's
      never that precise though because for produc�on efficiency purposes, we're not always going to
      run a product in the market that's made for the market. Some�mes that's not the most efficient
      thing to do. But I would consider it to be a meaningful component of the overall produc�on in
      China, is for China.
      Will Gartner
      Got it. And on ASPs, it looked like we saw some decelera�on, they turned nega�ve this quarter.
      Just curious if you have any color there, both on the wholesale side and the DTC side.
      John Vandemore
      Yeah. I mean nothing really outlandish. I think we saw small movements. I think more than
      anything, quite frankly, that's probably product mix associated. Certainly on the domes�c side
      of things. On the interna�onal side of things, there's definitely some of the effects impact gets
      led through on the ASPs. We don't adjust those for constant currency.
      On the direct to consumer side of things, there's small effects from mix, plus we con�nue to roll
      out more and more product with our Skechers Hands Free Slip-Ins technology as well as other
      comfort technologies. And so as those become more prevalent, just the life cycle of the product,
      they get included on certain promo�ons a litle bit more than they would have been in the past.
      So that gives a litle bit of -- it has a litle bit of an effect on ASPs. But overall I would say,
      generally speaking, they were prety consistent.
      Will Gartner
      Got it. And just maybe one last one for me on the DTC. So you said that you saw some traffic
      slow down in brick and mortar, but a pickup and e-comm. Can you maybe just frame out why
      you think that happened, what the delta was between the two, and why you think there was
      slow down traffic in brick and mortar versus e-commerce?
      John Vandemore
      Well, I don't know that I have the answer to that in all honesty. I would say it was prety
      consistent with a broader industry trend that we saw reported beginning in really late-May and
      con�nuing on into June. I would say what we saw was prety consistent with what the industry
      saw.
      On the e-comm side, I mean, I think that's probably mostly a testament to having the product
      people want available and our ability to fulfill quickly. And clearly a consumer can get that in a
      store as well, but if they're not in the prac�ce or not going to stores, the e-comm solu�on is a
      great fallback and having the right product, right marke�ng available to drive that I think was
      the difference.
      I would also just note again, last year our comps on the domes�c direct consumer side of things
      were fantas�c. And our e-commerce comps were actually trailing a bit because of a prior year
      issue. So there's some of it also that is just the comparison point is a bit different for each at this
      juncture.
      David Weinberg
      You know it's also fair to say that where people decide to shop is not only determined by our
      own stores and where we sell Skechers. People may go to different department stores or
      different malls or different parts to buy other things or other things that are being promoted
      and buy footwear on the site for their family, always searching for Skechers.
      So it depends on whether more people shop online through inclement weather, where it is, and
      regionally, and what's on promo�on, what they're shopping for, or what they're doing for
      entertainment. So they'll go and shop in different places at different �mes for various different
      reasons, not all pertaining to the way we promote or where our shoes are offered specifically.
      So that's why we're constantly saying that we want to be where our consumer prefers to shop,
      whether it's for Skechers or not. And we promote all three, knowing we capture that consumer
      somewhere along the line.
      Will Gartner
      Got it. Thank you.
      Operator
      Our next ques�on comes from Krisz�na Katai with Deutsche Bank
      Krisz�na Katai
      Hi, good a�ernoon. Thanks for taking the ques�on. I wanted to ask about EMEA at up 14%. It
      con�nues to do really well. Can you maybe unpack what you are seeing in the market for
      underlying demand? Have you seen any changes in consumer buying paterns at all? Just maybe
      comment on the exit trends that you saw in the region and if there's anything that we should
      expect sort of performance between the third quarter and the fourth quarter?
      John Vandemore
      The strength came out of DTC. I mean, the DTC numbers out of EMEA in par�cular, quite frankly,
      con�nue to surprise us to the upside. The products resona�ng, we con�nue to build into
      strength on our retail presence there. E-commerce also was an advantage in that marketplace
      which as you recall is really something we only began last year and have, I think, goten beter
      and beter at it.
      So that really came down to consumer demand. And I think that's why we also saw really good
      sell-through trends. We do expect that to con�nue. That is a consistent trend we've seen from
      the beginning of the year through today. And so we're prety confident that that will con�nue, 
      despite all the challenges that people have talked about the last two or three years. The EMEA
      direct consumer businesses con�nue to thrive.
      Krisz�na Katai
      Got it. That's really helpful.
      David Weinberg
      Some�mes it's a mater of �ming as well. You know, when things slow down in Europe and they
      pass through our distribu�on center, you have to realize at wholesale, it passes through
      basically two distribu�on centers. It goes from ours to theirs, and it's a lag. So some�mes as it
      happens in the opening a�er the pandemic.
      Our direct-to-consumer outlets, both online and stores, have more current inventory slightly
      faster. So you see the pickup in direct-to-consumer. And as it goes past it through, just like we're
      seeing here now, there's a leveling out at direct-to-consumer as the inventory and our newer
      product catches up with the wholesale side as well.
      So we feel confident in demand in both areas, but there's �ming of availability of new inventory
      changes, especially as you get into a new season, when there's so many supply chain
      disrup�ons along the way.
      Krisz�na Katai
      That's great. Thank you for that. I wanted to follow up also on domes�c trends. Just maybe if
      you have any sense on how back to school is performing and just how would you characterize
      the domes�c promo�onal environment. Thank you.
      John Vandemore
      I would characterize the promo�onal environment as largely the same as what we've seen the
      last couple of quarters. Nothing jumps out to us as indica�ng a significant change now or in the
      near future. You know, I think it's prety early to tell on back to school. We're only seeing the
      ini�al glances at it. So I don't know that there's really a read we can give that's meaningful at
      this juncture.
      Operator
      Our next ques�on comes from Chris Nardone with Bank of America.
      Chris Nardone
      Thanks, guys. Good a�ernoon. So regarding your US direct to consumer business, can you clarify
      whether you've changed your expecta�ons for the back half of the year rela�ve to your 
      thoughts last quarter? I'm just trying to gauge whether your improved sales guidance is solely
      due to the beter than expected order book trends.
      And then regarding product, if you can just elaborate a litle bit more on what categories are
      outperforming and whether you're s�ll seeing a broader trade up within your por�olio to your
      comfort technology products?
      John Vandemore
      Yeah, rela�ve to domes�c DTC, we had held back our view on the back half of the year in our
      earlier guidance. So I would say from this point, we really haven't changed it markedly. But we
      had always, I think, been conserva�ve about our view on the domes�c DTC front, knowing in
      par�cular what significant comps we were up against.
      But to more precisely answer your ques�on, you should construe that the vast majority of the
      eleva�on in our expecta�ons for the years coming from the wholesale side domes�cally and the
      order book there.
      Rela�ve to product, it's really not a division or a gender, only because we're seeing growth
      across a lot of those. I would say it is, as we've spoken about the last really year and a half, two
      years, maybe even longer, it's the benefit of the comfort technologies.
      And they are con�nuing to lead consumers to trade up within our por�olio, but that's clearly
      con�nuing to resonate, both Skechers Hands Free Slip-In, which is our newest, but also many of
      the others, Arch Fit, Max Cushioning, our Hyper burst technology, the tradi�onal Wide Fit that
      we offer, all of those are combining to very much translate at the consumer level to increased
      conversion. Because they know with those technologies, they're going to get more comfortable
      shoes than they can otherwise obtain in the market.
      So we are con�nuing to see that trend hold true, but our expecta�ons were and con�nue to be,
      I would say fairly modest on domes�c DTC for the back half of the year.
      Chris Nardone
      Thank you.
      Operator
      Our next ques�on comes from Tom Nikic with Wedbush Securi�es.
      Tom Nikic
      Hey, thanks for taking my ques�on. I want to ask about SG&A. Is there anything from a �ming
      perspec�ve that we should think about, Q3 versus Q4? Is there any lumpiness in marke�ng or
      anything like that as we work through our models?
      John Vandemore
      I knew I couldn't get off a call without somebody asking about G&A. The only thing I can point
      out is -- I appreciate that, Tom. We had men�oned previously that we were consciously overinves�ng in Q2. I think you can take it as that. It doesn't mean we're not inves�ng, but I think
      the investment rela�ve to the growth and sales we expect will be much more in line.
      I would say absent that, no, nothing really stands out. But that should mean to you that we'll
      con�nue to invest in new stores. We have new distribu�on coming online. And we're going to
      con�nue to put money into marke�ng.
      I will note, though, the line in our prepared remarks that we did see improved efficiency on the
      distribu�on side of things, which was good to see. Because that's been a reflec�on of a lot of
      work over the last years, given some of the challenges that we've had with supply chain. So that
      was actually a nice bright spot as well.
      But I would generally say you can expect con�nued investment on the marke�ng side, but not
      at the year over year increase that we did this quarter. This quarter was a focal point for us and
      we do believe that'll pay off going forward.
      Tom Nikic
      Understood. If I could follow up with one more. on US wholesale. Have you found that at the
      consumer level that the acceptance of and the excitement around the Slip-In products and the
      new technologies are as robust as what you had seen in DTC previously?
      John Vandemore
      Yes, yes. I think some of that though, to be clear, is somewhat reflec�ve of the �ming through
      which wholesale accounts have taken up the technology. What we see is when they order the
      technology, when they order what we're bringing to market new, they see incredible response
      for those technologies. And I'd say very commensurate with what we've seen in our DTC.
      Tom Nikic
      Great. Thanks very much, John and David, and best of luck the rest of the year.
      John Vandemore
      Thanks, Tom.
      Operator
      We have reached the end of the ques�on-and-answer session. T his concludes today's
      conference. You may disconnect your lines at this �me. And we thank you for your par�cipa�on.
    `,
    config: {
      temperature: 0.4,
      topP: 0.75,
      topK: 40,
      maxTokens: 2000,
      stopSequences: [],
    }
  },
];

export default UseCases;